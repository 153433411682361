<template>
  <v-container class="pa-0">
    <v-list three-line>
      <transition-group name="fade" tag="div">
        <kurcc-inspection-list-item v-for="(inspection, index) in inspections" :key="index" :inspection="inspection"
                                    :show-select-button="showSelectButton" @change="$emit('change')"
                                    @check-changed="changeTodayList($event, index)">
          <v-divider v-if="index !== inspections.length -1" slot="divider"/>
        </kurcc-inspection-list-item>
      </transition-group>
    </v-list>
    <kurcc-infinite-loading :filter="filter" :items.sync="inspections" action-name="getAllInspections"/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccInspectionList',
  props: {
    filter: {
      type: Array,
      required: false
    },
    showSelectButton: {
      type: Boolean,
      required: false
    },
    selectedIds: {
      type: Array,
      required: false
    }
  },
  components: {
    KurccInspectionListItem: () => import('@/modules/app/components/KurccInspectionListItem'),
    KurccInfiniteLoading: () => import('@/modules/app/components/KurccInfiniteLoading')
  },
  data () {
    return {
      inspections: []
    }
  },
  methods: {
    changeTodayList (value, index) {
      const ids = [...this.selectedIds]

      if (value) {
        ids.push(this.inspections[index].id)
      } else {
        ids.splice(ids.findIndex(id => id === this.inspections[index].id), 1)
      }

      this.$emit('update:selected-ids', ids)
    }
  }
}
</script>
